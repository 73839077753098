body {
    background-color: rgb(37, 36, 36);
    color: rgb(228, 225, 225);
    font-size: 20px;
    margin:0px;
  }

  .title{
    font-size: 66px;
  }

  .title:hover {
    color: rgb(242, 196, 13);
    transition: 0.4s;
  }

.center-vert {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;  /* Use the full height of the viewport */
    margin: 0;      /* Reset default margins */
  }
  
  .centre-col {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center children horizontally */
    justify-content: center;  /* Center children vertically */
  }
  
  .page-borders {
    margin-left: 50px;
    margin-right: 50px;
  }

  .menu {
    margin-top: 10px;
  }

  .menu-items {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(228, 225, 225);
    background-color: rgb(37, 36, 36);
    border: 2px solid #4CAF50;  /* Green border */
    cursor: pointer;
    font-size: 40px;
    border-radius: 1px;  /* Optionally rounded corners */
  }

  .menu-button{
    margin-left: 12px;
    margin-right: 12px;
    color: rgb(228, 225, 225);
    background-color: rgb(37, 36, 36);
    border: 2px solid rgb(228, 225, 225);  /* Green border */
    cursor: pointer;
    font-size: 40px;
    border-radius: 5px;  /* Optionally rounded corners */
    padding-left:10px;
    padding-top:5px;
    padding-right:10px;
    padding-bottom:5px;

  }

  .menu-button:hover {
    color: rgb(242, 196, 13);
    transition: 0.4s;
  }

.button-container{
  display: flex;
  flex-direction: row;
  }

