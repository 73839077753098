.counter-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.triangle-up-mobile, .triangle-down-mobile {
    width: 0;
    height: 0;
    cursor: pointer;
    margin: 20px; /* Spacing around the triangles */
}

.triangle-up-mobile {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 45px solid rgb(228, 225, 225); /* Height of the equilateral triangle */
}

.triangle-up-mobile:hover {
    transition: 0.25s;
    border-bottom-color: rgb(242, 196, 13); /* Changes only the bottom border color */
}

.triangle-down-mobile {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 45px solid rgb(228, 225, 225); /* Height of the equilateral triangle */
}

.triangle-down-mobile:hover {
    transition: 0.25s;
    border-top-color: rgb(242, 196, 13); /* Changes only the top border color */
}

.count-display-mobile {
    margin-left: 20px; /* Spacing between the button and the text */
    font-size: 38px; /* Display font size */
    font-weight: bold; /* Making text bold */
    width: 150px; /* Enough to handle '99-Back' */
    overflow: hidden; /* Hides any content that overflows the box */
    white-space: nowrap; /* Prevents text from wrapping */
}
