body {
  background-color: rgb(37, 36, 36);
  color: rgb(228, 225, 225);
  font-size: 20px;
  margin: 0px;
}
 
.content-donate{
  margin-top:70px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(22, 22, 22);
  padding: 10px 20px;
}

.title-button {
  padding-top:10px;
  font-size: 40px;
  margin: 0;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
}

@media (max-width: 640px) {
  .title-button {
     font-size:32px;
  }
}

.menu {
  display: flex;
}

.menu-buttons {
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
  border: 2px solid rgb(228, 225, 225);
  cursor: pointer;
  font-size: 24px;
  padding: 5px 10px;
  border-radius: 5px;
}

.menu-buttons:hover {
  color: rgb(242, 196, 13);
  transition: color 0.4s;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-container {
display: flex;
justify-content: center;  /* Aligns children (images) horizontally at the center */
flex-direction: row;
max-width:600px;
}

.wallet-address {
margin: 10px;
height: auto;        /* Maintains aspect ratio */
max-height: 150px;   /* Maximum height of the image */
width: auto;         /* Adjust width automatically */
padding:1px;
border:2px solid rgb(228, 225, 225);
border-radius:2px;
}

.wallet-text {
font-size: 16px;
text-align: center;    /* Centers the text */
margin-top: 10px;      /* Adds margin on the top for spacing from images */
}

@media (max-width: 750px) {
.wallet-address {
  max-height: 70px;  /* Smaller images on small screens */
  margin-left: 35px;
}

.wallet-address{
  margin:5px;
}

.wallet-text {
  font-size: 14px;    /* Smaller text on small screens */
}

}

/* Mobile CSS */

.content-donate-mobile{
  margin-top:70px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }

.app-container-mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
  }

.top-bar-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(22, 22, 22);
  padding: 10px 20px;
  min-height: 100px
  }

  /*     */

.title-button-mobile {
  padding-top:10px;
  font-size: 66px;
  margin: 0;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
  }

.title-button-mobile:hover{
  color: rgb(242, 196, 13);
  transition: color 0.4s;
  }

.menu-mobile {
margin-top: 10px;
}

  /*     */

.menu-buttons-mobile {
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
  border: 2px solid rgb(228, 225, 225);
  cursor: pointer;
  font-size: 38px;
  padding: 5px 10px;
  border-radius: 5px;
  }

.menu-buttons-mobile:hover {
  color: rgb(242, 196, 13);
  transition: 0.4s;
  }

 /*     */

.wallet-container-mobile {
display: flex;
justify-content: center;  /* Aligns children (images) horizontally at the center */
flex-direction: row;
max-width:600px;
}

.wallet-address-mobile {
margin: 10px;
height: auto;        /* Maintains aspect ratio */
max-height: 200px;   /* Maximum height of the image */
width: auto;         /* Adjust width automatically */
padding:1px;
border:2px solid rgb(228, 225, 225);
border-radius:2px;
}

.wallet-text-mobile {
font-size: 34px;
text-align: center;    /* Centers the text */
margin-top: 10px;      /* Adds margin on the top for spacing from images */
}

.wallet-address-mobile{
margin:5px;
}

.wallet-text-mobile {
font-size: 34px;    /* Smaller text on small screens */
}
  
  
.wallet-container-container, .wallet-container-container-mobile {
  display: flex;
  justify-content: center;  
  align-items: center;    
  flex-direction: column;  
  width: 100%;              
}