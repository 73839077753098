/* General form styling */
.stripe-form {
    max-width: 500px;
    margin: 20px;
    padding: 20px;
    background: rgb(37, 36, 36);
    border-radius: 8px;
    border: 2px solid rgb(228, 225, 225);
    width: 100%;
    justify-content: center;
  }
  
  @media (max-width: 750px) {
    .stripe-form {
       width:400px;
    }
  }

  /* Styling for the Stripe CardElement */
  .StripeElement {
    background-color: white;
    padding: 16px 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin: 10px 14px 10px 14px;
    max-width: calc(100% - 28px); /* Account for padding and border */
    width: 100%;
  }
  
  /* Styling for focusing on CardElement */
  .StripeElement--focus {
    border-color: blue;
  }
  
  /* Styling for invalid input in CardElement */
  .StripeElement--invalid {
    border-color: red;
  }
  
  /* Button styling */
  .stripe-button {
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left:14px;
    margin-right:14px;
    margin-bottom:10px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.2s ease;
    display: block;
    width: calc(100% - 28px);
  }
  
  .stripe-button:hover {
    background-color: #45a049; /* Darker green on hover */
    
  }
  
  /* Disable button style */
  .stripe-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .donation-input{
    margin-bottom:25px;
    margin-left:14px;
    margin-right:14px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border:none;
  }

  .donation-buttons{
    background-color: #45a049; /* Darker green on hover */
    border:none;
    border-radius: 5px;
    padding-left:10px;
    padding-right:10px;
    margin:5px;
    margin-left:10px;
    color:white;
  }

  .second-row{
    display:flex;
    flex-direction: row;
    padding:10px;
    align-items: center;
  }

  .donation-input, .donation-buttons {
    flex-grow: 1; /* Allow buttons and input to grow */
    margin: 0 5px; /* Spacing between elements */
    padding: 8px;
    border-radius: 4px;
}

/* Media query for devices with a max width of 768px 
@media (max-width: 640px) {
    .second-row {
       flex-direction: column;
    }
  }
*/
  