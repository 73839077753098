.counter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.triangle-up, .triangle-down {
    width: 0;
    height: 0;
    cursor: pointer;
    margin: 5px; /* Spacing around the triangles */
}

.triangle-up {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 21.21px solid rgb(228, 225, 225); /* Height of the equilateral triangle */
}

.triangle-up:hover {
    transition: 0.25s;
    border-bottom-color: rgb(242, 196, 13); /* Changes only the bottom border color */
}

.triangle-down {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 21.21px solid rgb(228, 225, 225); /* Height of the equilateral triangle */
}

.triangle-down:hover {
    transition: 0.25s;
    border-top-color: rgb(242, 196, 13); /* Changes only the top border color */
}

.count-display {
    margin-left: 20px; /* Spacing between the button and the text */
    font-size: 18px; /* Display font size */
    font-weight: bold; /* Making text bold */
    width: 80px; /* Enough to handle '99-Back' */
    overflow: hidden; /* Hides any content that overflows the box */
    white-space: nowrap; /* Prevents text from wrapping */
}
