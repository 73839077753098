.panel-background {
  background-color: rgb(37, 36, 36);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid rgb(228, 225, 225);
  width: 220px;
  margin-top: 40px;
  height:165px;
}

.sizing-box {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
}

.menu-items-button {
  background-color: rgb(228, 225, 225);
  color: rgb(37, 36, 36);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 70px; /* Ensures all buttons have the same width */
}

.menu-items-button:hover {
  color: rgb(242, 196, 13);
  background-color: rgb(22, 22, 22);
  border-color: rgb(228, 225, 225);
  transition: 0.25s;
}

.menu-items-button:active{
  background-color: rgb(242, 196, 13); /* Darker green on click */
  box-shadow: 1px 1px 1px 1px #666; /* Add a shadow to simulate press */
  transform: translateY(3px); /* Push the button down */
}

.menu-items-text {
  background-color: rgb(37, 36, 36);
  color: rgb(228, 225, 225); /* Ensure the text color is set for visibility */
  display: inline-block; /* Align text properly */
  width: 100px; /* Fixed width to align text uniformly */
}

.panel-title{
    width:100%;
    font-size: 20px;;
}

@media (max-width: 600px) {
  .menu-items-text {
    width:70px;
  }
  .panel-title{
    font-size: 16px
  }
  .panel-background{
    width: 190px;
  }
}
