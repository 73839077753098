/* General body and HTML styling to ensure the footer sticks to the bottom */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.site-footer {
    width: 100%;
    background-color: rgb(37, 36, 36); /* Dark background for the footer */
    color: #fff; /* White text color */
    text-align: center; /* Centers the text inside the footer */
    
    margin-top: auto; /* Pushes the footer to the bottom of the content */
    font-size: 16px; /* Sets the font size */
    border-top: 1px solid #444; /* Adds a subtle border top for stylistic separation */
}

.footer-content {
    margin: 0 auto; /* Center the footer content horizontally */
    width: 90%; /* Limiting the width for better reading on large screens */
    max-width: 1200px; /* Maximum width */
}

.footer-content a {
    color: #4CAF50; /* Stylish green color for links for better visibility */
    text-decoration: none; /* Removes underline from links */
}

.footer-content a:hover, .footer-content a:focus {
    text-decoration: underline; /* Underline on hover for better accessibility */
}

.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100%; /* Full view height */
    justify-content: space-between; /* Aligns content to space between which pushes the footer down */
}

.info-container{
    margin: 20px;
}

.info-title{
    color: rgb(228, 225, 225);
    font-size:36px;
}

.info-text{
    color: rgb(228, 225, 225);
    font-size: 20px;
}

.menu {
    margin-top: 10px;
  }

  .menu-buttons {
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .menu-buttons:hover {
    color: rgb(242, 196, 13);
    transition: 0.4s;
  }

.how-to-play-button{
    margin-left: 5px;
    margin-right: 5px;
    color: rgb(228, 225, 225);
    background-color: rgb(37, 36, 36);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;   /* Optionally rounded corners */
}

.how-to-play-button:hover{
    color: rgb(242, 196, 13);
    transition: 0.4s;
}

.info-text-link{
    color: rgb(228, 225, 225);
    font-size: 20px;
}


.info-text-link:hover{
    color: rgb(242, 196, 13);
    transition: 0.4s;
}

.bottom-text{
    color: rgb(228, 225, 225);
    font-size: 20px;
    margin-top:80px;
    margin-bottom:0px;
}

/* Mobile CSS */


.top-bar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(22, 22, 22);
    padding: 10px 20px;
    min-height: 100px
  }
  
  .title-button-mobile {
    padding-top:10px;
    font-size: 66px;
    margin: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
  }
  
  .title-button-mobile:hover{
    color: rgb(242, 196, 13);
    transition: color 0.4s;
  }
  
  .menu {
    display: flex;
  }
  
  .menu-buttons-mobile {
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 38px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .menu-buttons-mobile:hover {
    color: rgb(242, 196, 13);
    transition: color 0.4s;
  }





.site-container-mobile {
    display: flex;
    flex-direction: column;
    min-height: 100%; /* Full view height */
    justify-content: space-between; /* Aligns content to space between which pushes the footer down */
}

.info-container-mobile{
    margin: 20px;
}

.info-title-mobile{
    color: rgb(228, 225, 225);
    font-size:54px;
}

.info-text-mobile{
    color: rgb(228, 225, 225);
    font-size: 38px;
}

.menu-mobile {
    margin-top: 10px;
  }

.menu-buttons-mobile {
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 38px;
    padding: 5px 10px;
    border-radius: 5px;
  }

.menu-buttons-mobile:hover {
    color: rgb(242, 196, 13);
    transition: 0.4s;
  }

.how-to-play-button-mobile{
    margin-left: 5px;
    margin-right: 5px;
    color: rgb(228, 225, 225);
    background-color: rgb(37, 36, 36);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 38px;
    border-radius: 5px;   /* Optionally rounded corners */
}

.how-to-play-button-mobile:hover{
    color: rgb(242, 196, 13);
    transition: 0.4s;
}

.info-text-link-mobile{
    color: rgb(228, 225, 225);
    font-size: 30px;
}


.info-text-link-mobile:hover{
    color: rgb(242, 196, 13);
    transition: 0.4s;
}

.bottom-text-mobile{
    color: rgb(228, 225, 225);
    font-size: 30px;
    margin-top:80px;
    margin-bottom:0px;
}

.info-content-mobile{
    margin-left: 15px;
  margin-right: 15px;
}