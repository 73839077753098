body {
    background-color: rgb(37, 36, 36);
    color: rgb(228, 225, 225);
    margin: 0px;
  }
   
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(22, 22, 22);
    padding: 10px 20px;
  }
  
  .title-button {
    padding-top:10px;
    font-size: 40px;
    margin: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
  }

  @media (max-width: 640px) {
    .title-button {
       font-size:32px;
    }
  }

  .title-button:hover{
    color: rgb(242, 196, 13);
    transition: color 0.4s;
  }

  .menu {
    display: flex;
  }
  
  .menu-buttons {
    color: rgb(228, 225, 225);
    background-color: rgb(22, 22, 22);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 24px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .menu-buttons:hover {
    color: rgb(242, 196, 13);
    transition: color 0.4s;
  }
  
  .page-content{
    justify-content: start;  /* This aligns all children to the top of the container */
    height: 100vh;
  }

  .content-title{
    margin-left:20px;
    padding-left: 100px;
    font-size: 26px;
  }

  .what-is-it{
    margin-top: 80px;
  }

  .how-to-play{
    display: block;
    justify-content: left;
    align-items: left;
    margin-left:20px;
    margin-right:25px;
    padding-left: 100px;
    padding-bottom: 40px;
  }

  .about-page-content {
    margin-left:20px;
    margin-right:20px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .play-button-placement{
    display: flex;        /* Enables flexbox layout */
    justify-content: center;  /* Centers horizontally */
    align-items: center;      /* Centers vertically */ 
    padding-top:10px;     /* Full height of the viewport */
    margin: 0;
  }

  .click-container{
    display: flex;
    align-items: center; /* This will vertically center align the text and button */
    justify-content: flex-start;
    margin:0px;
    padding:0px;
  }

  .click-text{
    padding-right:10px;
  }
  
  .bottom-text-info{
    margin-top:70px;
  }

  .other-button{
    margin-left: 5px;
    margin-right: 5px;
    color: rgb(228, 225, 225);
    background-color: rgb(37, 36, 36);
    border: 2px solid rgb(228, 225, 225);
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;   /* Optionally rounded corners */
}

.other-button:hover{
    color: rgb(242, 196, 13);
    transition: 0.4s;
}

/* Mobile CSS */


.app-container-mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-bar-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(22, 22, 22);
  padding: 10px 20px;
}

.title-button-mobile {
  padding-top:10px;
  font-size: 66px;
  margin: 0;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
}

.title-button-mobile:hover{
  color: rgb(242, 196, 13);
  transition: color 0.4s;
}
.menu-mobile {
  margin-top: 10px;
}

.menu-buttons-mobile {
  color: rgb(228, 225, 225);
  background-color: rgb(22, 22, 22);
  border: 2px solid rgb(228, 225, 225);
  cursor: pointer;
  font-size: 38px;
  padding: 5px 10px;
  border-radius: 5px;
}

.menu-buttons-mobile:hover {
  color: rgb(242, 196, 13);
  transition: 0.4s;
}

.page-content-mobile{
  justify-content: start;  /* This aligns all children to the top of the container */
  height: 100vh;
  margin-left: 10px;
  margin-right: 10px;
}

.content-title-mobile{
  margin-left:10px;
  padding-left: 20px;
  font-size: 54px;
}

.what-is-it-mobile{
  margin-top: 80px;
}

.how-to-play-mobile{
  display: block;
  justify-content: left;
  align-items: left;
  margin-left:10px;
  margin-right:10px;
  padding-left: 20px;
  padding-bottom: 40px;
}

.content-about-mobile {
  margin-left:10px;
  margin-right:10px;
  padding-left: 20px;
  padding-right: 20px;
}

.play-button-placement-mobile{
  display: flex;        /* Enables flexbox layout */
  justify-content: center;  /* Centers horizontally */
  align-items: center;      /* Centers vertically */ 
  padding-top:10px;     /* Full height of the viewport */
  margin: 0;
}

.click-container-mobile{
  display: flex;
  align-items: center; /* This will vertically center align the text and button */
  justify-content: flex-start;
  margin:0px;
  padding:0px;
}

.click-text-mobile{
  padding-right:10px;
}

.bottom-text-info-mobile{
  margin-top:70px;
}

.other-button-mobile{
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(228, 225, 225);
  background-color: rgb(37, 36, 36);
  border: 2px solid rgb(228, 225, 225);
  cursor: pointer;
  font-size: 38px;
  border-radius: 5px;   /* Optionally rounded corners */
}

.other-button-mobile:hover{
  color: rgb(242, 196, 13);
  transition: 0.4s;
}

.bulletpoints {
  font-size: 38px;
  color: rgb(228, 225, 225);
}

.about-text-mobile{
  font-size: 38px;
  color: rgb(228, 225, 225);
}