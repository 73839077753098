.game-container{
    display:flex;
    max-width: 300px;
    margin: 20px auto;
}

.all-content{
    display: flex;   
    justify-content: space-between;
}

/*Content responsible for the margin above the game*/

.content{
    display: flex;        /* Aligns children (buttons) in a row */
    margin-bottom: 50px;
    margin: none;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    width: 315px; /* Explicit width to align with the answer container */
    margin: 0 auto; /* Centers the grid horizontally within its container */
}

.square {
    width: 100px;
    height: 100px;
    transition: background-color 0.15s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px;
    border-style: solid;
    border-radius:5px;
}

.controls {
    text-align: center;
    margin: 20px; /* suspicious one here */
}

.control-panel {
    display: grid;
    grid-template-columns: repeat(3, auto); /* Adjusts columns to fit the content size */
    gap: 5px; 
    justify-content: start; /* Aligns buttons to the start of the container */
}

button {
    margin: 0 10px;
    padding: 5px 10px;
}

.start-stop{
    background-color: rgb(228, 225, 225);
    color: rgb(37, 36, 36);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 70px;
}

.start-stop:hover{
    color: rgb(242, 196, 13);
    background-color: rgb(22, 22, 22);
    border-color: rgb(228, 225, 225);
    transition:0.25s;
}

.start-stop:active{
    background-color: rgb(242, 196, 13); /* Darker green on click */
    box-shadow: 1px 1px 1px 1px #666; /* Add a shadow to simulate press */
    transform: translateY(3px); /* Push the button down */
}



.answer-buttons:hover {
    color: rgb(242, 196, 13);
    background-color: rgb(22, 22, 22);
    transition: 0.25s;
}

.answer-buttons:active{
    background-color: rgb(242, 196, 13); /* Darker green on click */
    box-shadow: 1px 1px 1px 1px #666; /* Add a shadow to simulate press */
    transform: translateY(3px); /* Push the button down */
}

.center-answer-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically centering if needed */
    width: 100%; /* Ensures full width to center `.answer-container` properly */
}
  
.answer-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px; 
    width: 100%; 
    max-width: 600px; 
    margin: 20px auto;
    padding: 0 20px; 
}

.answer-buttons {
    width: 100%; 
    padding: 8px; 
    background-color: rgb(228, 225, 225);
    color: rgb(37, 36, 36);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; 
}


.center-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* This should align all child elements centrally */
    width: 100%;
}

.counter-center-container{
    display: flex;
    justify-content: center;
}

.counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right:100px;
}

.round-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.round-limit-input{
    background-color: rgb(22, 22, 22);
    border: none;
    width: fit-content;
    color: rgb(228, 225, 225);
}

.round-label {
    margin-right: 2px;
    font-size:20px;
}

.round-limit-input {
    height: 30px;
    font-size: 20px;
    width: 50px;
    background-color: rgb(37, 36, 36);
    color: rgb(228, 225, 225); /* Assuming you want the text color to be white for visibility */
    margin-top: 3px;
    border-bottom: 2px solid rgb(228, 225, 225);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-style: solid;
    text-align: center; /* Center text horizontally */
    outline: none; /* Removes the focus outline */
    /* Adjust padding to center text vertically */
    padding: 4px 0; /* Adjust the vertical padding as needed */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.panel-center-container {
    display: flex;
    justify-content: center;  /* Horizontally center */
    align-items: center;      /* Vertically center */
    width: 100%;              /* Optional, depending on your layout needs */
    margin-bottom:0px;
}

.bottom-row-alignment {
    display: flex;
    flex-direction: row; /* Start with a row by default */
    margin-right: 100px;
    justify-content: center;  /* Horizontally center */
    width: 100%;              /* Optional, depending on your layout needs */
}



/* Shapes */

.shape-triangle {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 50px solid; /* Color set dynamically */
}

.shape-circle {
    width: 50px;
    height: 50px;
    background-color: currentColor;
    border-radius: 50%;
}

.shape-square {
    width: 50px;
    height: 50px;
    background-color: currentColor; /* Inherits color from parent */
}

.shape-star {
    position: relative;
    width: 60px;  /* Adjust star size as needed */
    height: 60px; /* Adjust star size as needed */
    background-color: transparent;
    clip-path: polygon(
        50% 0%, /* top center */
        61% 35%, /* right upper inner */
        98% 35%, /* right outer */
        68% 57%, /* right lower inner */
        79% 91%, /* right bottom */
        50% 70%, /* bottom center */
        21% 91%, /* left bottom */
        32% 57%, /* left lower inner */
        2% 35%, /* left outer */
        39% 35% /* left upper inner */
    );
    margin: 15px; /* Additional margin to ensure it fits and centers in a 90x90 box */
}



/* Table Settings */

.performance-table {
    width: 100%;
    border-collapse: separate; /* Important for applying rounded corners to tables */
    border-spacing: 0; /* Removes the default spacing between borders */
    margin: 10px 50px; /* Top margin and horizontal margins for alignment */
    max-width: 500px;
    border: 2px solid rgb(228, 225, 225); /* Wider border for the whole table */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures that the content does not spill out */
}

.perform-th, .perform-td {
    border-bottom: 2px solid rgb(228, 225, 225); /* Wider border for separation */
    padding: 8px; /* Padding inside each cell for better text display */
    text-align: left; /* Aligns the text to the left */
}

.perform-th:first-child, .perform-td:first-child {
    border-left: 2px solid rgb(228, 225, 225); /* Adds left border to the first cell */
}

.perform-th:last-child, .perform-td:last-child {
    border-right: 2px solid rgb(228, 225, 225); /* Adds right border to the last cell */
}

.performance-table thead th {
    border-bottom: 2px solid rgb(228, 225, 225); /* Wider border for the header bottom */
}

.performance-table tbody tr:last-child td {
    border-bottom: 2px solid rgb(228, 225, 225); /* Ensures bottom border is visible on the last row */
}

.table-center-container {
    display: flex;
    justify-content: center;  /* Horizontally center */
    align-items: center;      /* Vertically center */
    width: 100%;              /* Optional, depending on your layout needs */
}


@media (max-width: 1290px) {
    .content {
      flex-direction:column;
    }
    .counter-container {
      flex-direction:row;
      margin-bottom:50px;
      margin-right:0px;
    }
}

@media (max-width: 1200px) {
    .all-content {
      flex-direction:column;
    }
}

.button-panel-mobile{
    margin-bottom: 30px;
}

.button-panel-mobile{
    display:flex;
    justify-content: center;
    flex-direction: row;
}

.mobile-container{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.grid-mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    width: 92vw;
    margin: none;
}

.square-mobile {
    width: 28vw;
    height: 28vw;
    transition: background-color 0.15s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px;
    border-style: solid;
    border-radius:5px;
}

.center-answer-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically centering if needed */
    width: 100%; /* Ensures full width to center `.answer-container` properly */
}

.answer-container-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    width: 100%; 
    max-width: 600px; 
    margin-top: 30px;
    margin-right: 10px;
    padding: 0 20px;

}

.answer-buttons-mobile {
    width: 100%; 
    padding: 25px; 
    background-color: rgb(228, 225, 225);
    color: rgb(37, 36, 36);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; 
    font-size: 40px;
}

.top-row-mobile{
    display:flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.counter-center-container-mobile{
    display: flex;
    justify-content: center;
    margin-right: 25px;
}

.setting-text{
    text-align:center;
    font-size: 38px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.start-stop-mobile-screen-one{
    background-color: rgb(228, 225, 225);
    color: rgb(37, 36, 36);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 230px;
    padding:20px;
    font-size: 30px;
}

.start-stop-mobile-screen-two{
    background-color: rgb(228, 225, 225);
    color: rgb(37, 36, 36);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
    font-size: 38px;
}

.start-stop-mobile-screen-one:hover{
    color: rgb(242, 196, 13);
    background-color: rgb(22, 22, 22);
    border-color: rgb(228, 225, 225);
    transition:0.25s;
}

.start-stop-mobile-screen-one:active{
    background-color: rgb(242, 196, 13); /* Darker green on click */
    box-shadow: 1px 1px 1px 1px #666; /* Add a shadow to simulate press */
    transform: translateY(3px); /* Push the button down */
}

.start-stop-mobile-screen-two:hover{
    color: rgb(242, 196, 13);
    background-color: rgb(22, 22, 22);
    border-color: rgb(228, 225, 225);
    transition:0.25s;
}

.start-stop-mobile-screen-two:active{
    background-color: rgb(242, 196, 13); /* Darker green on click */
    box-shadow: 1px 1px 1px 1px #666; /* Add a shadow to simulate press */
    transform: translateY(3px); /* Push the button down */
}

.round-input-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:10px;
}

.performance-table-mobile {
    width: 100%;
    border-collapse: separate; /* Important for applying rounded corners to tables */
    border-spacing: 0; /* Removes the default spacing between borders */
    margin: 10px 50px; /* Top margin and horizontal margins for alignment */
    max-width: 80vw;
    border: 4px solid rgb(228, 225, 225); /* Wider border for the whole table */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures that the content does not spill out */
    font-size: 28px;
}

.perform-th-mobile, .perform-td-mobile {
    border-bottom: 4px solid rgb(228, 225, 225); /* Wider border for separation */
    padding: 20px; /* Padding inside each cell for better text display */
    text-align: left; /* Aligns the text to the left */
}

.perform-th-mobile:first-child, .perform-td-mobile:first-child {
    border-left: solid rgb(228, 225, 225); /* Adds left border to the first cell */
}

.perform-th-mobile:last-child, .perform-td-mobile:last-child {
    border-right: 4px solid rgb(228, 225, 225); /* Adds right border to the last cell */
}

.performance-table-mobile thead th {
    border-bottom: 4px solid rgb(228, 225, 225); /* Wider border for the header bottom */
}

.performance-table-mobile tbody tr:last-child td {
    border-bottom: 4px solid rgb(228, 225, 225); /* Ensures bottom border is visible on the last row */
}

.round-input-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.round-limit-input-mobile {
    background-color: rgb(22, 22, 22);
    border: none;
    width: fit-content;
    color: rgb(228, 225, 225);
}

.round-label-mobile {
    margin-right: 2px;
    font-size:38px;
}

.round-limit-input-mobile {
    height: 50px;
    font-size: 38px;
    width: 85px;
    background-color: rgb(37, 36, 36);
    color: rgb(228, 225, 225); /* Assuming you want the text color to be white for visibility */
    margin-top: 3px;
    border-bottom: 2px solid rgb(228, 225, 225);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-style: solid;
    text-align: center; /* Center text horizontally */
    outline: none; /* Removes the focus outline */
    /* Adjust padding to center text vertically */
    padding: 4px 0; /* Adjust the vertical padding as needed */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


/* Shapes on Mobile */

.shape-triangle-mobile {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid; /* Color set dynamically */
}

.shape-circle-mobile {
    width: 100px;
    height: 100px;
    background-color: currentColor;
    border-radius: 50%;
}

.shape-square-mobile {
    width: 100px;
    height: 100px;
    background-color: currentColor; /* Inherits color from parent */
}

.shape-star-mobile {
    position: relative;
    width: 100px;  /* Adjust star size as needed */
    height: 100px; /* Adjust star size as needed */
    background-color: transparent;
    clip-path: polygon(
        50% 0%, /* top center */
        61% 35%, /* right upper inner */
        98% 35%, /* right outer */
        68% 57%, /* right lower inner */
        79% 91%, /* right bottom */
        50% 70%, /* bottom center */
        21% 91%, /* left bottom */
        32% 57%, /* left lower inner */
        2% 35%, /* left outer */
        39% 35% /* left upper inner */
    );
    margin: 15px; /* Additional margin to ensure it fits and centers in a 90x90 box */
}
